/*  ==========================================================================
    ==========================================================================

    Footer
    1. footer

    CSS FORMATTING
    1. Positioning
    2. Display & Box Model
    3. Text
    4. Other

    ==========================================================================
    ========================================================================== */

/*  ========================================================================== 
	1. footer
    ========================================================================== */
    .footer {
        width: 100%;
        padding: 40px 0px;
        background-color: $color-brand-1;
    }

    .footer__links {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        a {
            margin-right: 20px;
            @include transition();

            &:last-child {
                margin-right: 0px;
            }

            &:hover, &:active, &:focus {
                color: $color-brand-4;
            }
        }

        @include breakpoint($break-600) {
            display: block;
            padding: 0px 20px;
            @include cf();
            
            a {
                @include col(1/2, $cycle: 2);
                margin-bottom: 10px;
                text-align: center;
            }
        }

        @include breakpoint($break-480) {            
            a {
                @include stack();
                text-align: center;
            }
        }
    }

    .footer__phone-numbers {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        a {
            margin-right: 20px;
            @include transition();

            &:last-child {
                margin-right: 0px;
            }

            &:hover, &:active, &:focus {
                color: $color-brand-4;
            }
        }

        @include breakpoint($break-600) {
            display: block;
            padding: 0px 20px;
            @include cf();
            
            a {
                @include col(1/2, $cycle: 2);
                margin-bottom: 10px;
                text-align: center;
            }
        }

        @include breakpoint($break-480) {
            display: block;
            padding: 0px 20px;
            @include cf();
            
            a {
                @include stack();
                margin-bottom: 10px;
                text-align: center;
            }
        }
    }

    .footer__submenu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 10px;
        @include font-size(14);

        a {
            flex: auto 0 1;
            margin-right: 20px;
            @include transition();

            &:last-child {
                margin-right: 0px;
            }

            &:hover, &:active, &:focus {
                color: $color-brand-4;
            }

            &.no-link {
                color: $color-text-1;
                &:hover, &:active, &:focus {
                    color: $color-text-1;
                    cursor: default;
                }
            }
        }

        @include breakpoint($break-1024) {
            display: block;
            padding: 0px 20px;
            @include cf();
            
            a {
                @include col(1/4, $cycle: 4);
                margin-bottom: 10px;
                text-align: center;
            }
        }

        @include breakpoint($break-767) {            
            a {
                @include col(1/3, $uncycle: 4, $cycle: 3);
                text-align: center;
            }
        }

        @include breakpoint($break-600) {            
            a {
                @include col(1/2, $uncycle: 3, $cycle: 2);
                text-align: center;
            }
        }

        @include breakpoint($break-480) {            
            a {
                @include stack();
                text-align: center;
            }
        }
    }





