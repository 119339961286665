/*  ==========================================================================
    ==========================================================================

    Header
    1. header

    CSS FORMATTING
    1. Positioning
    2. Display & Box Model
    3. Text
    4. Other

    ==========================================================================
    ========================================================================== */

/*  ========================================================================== 
	1. header
    ========================================================================== */
    .header {
        width: 100%;
        border-bottom: solid 1px $color-brand-2;

        &__container {
            width: 100%;
            @include center(1200px);
            padding: 24px 5%;
        }

        &__logo {
            width: 100%;
            max-width: 300px;
        }
    }