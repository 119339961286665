/*  ==========================================================================
    ==========================================================================

    Header
    1. container

    CSS FORMATTING
    1. Positioning
    2. Display & Box Model
    3. Text
    4. Other

    ==========================================================================
    ========================================================================== */

/*  ========================================================================== 
	1. container
    ========================================================================== */
    .container {
        width: 100%;
        @include center(940px);
        padding: 24px 5%;
    }