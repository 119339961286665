/*  ==========================================================================
    ==========================================================================

    Forms
    1. Standard Fields
    2. Form Specific Styles

    CSS FORMATTING
    1. Positioning
    2. Display & Box Model
    3. Text
    4. Other

    ==========================================================================
    ========================================================================== */

/*  ========================================================================== 
    1. Standard Fields
    ========================================================================== */

/*  Fieldset
    ========================================================================== */

    fieldset {
        background: lighten($base-border-color, 10);
        border: $base-border;
        margin: 0 0 ($base-spacing / 2) 0;
        padding: $base-spacing;
    }

/*  Common 
    ========================================================================== */

    input,
    label,
    select {
        display: block;
        @extend .sans-regular;
        @include font-size($body-font-size);
    }

/*  Label
    ========================================================================== */

    label {
        font-weight: bold;
        margin-bottom: $base-spacing / 4;

        &.required:after {
            content: "*";
        }

        abbr {
            display: none;
        }
    }

/*  Textarea / Select
    ========================================================================== */

    textarea,
    #{$all-text-inputs},
    select[multiple=multiple] {
        // @include transition(border-color);
        background-color: white;
        border-radius: $form-border-radius;
        border: 1px solid $color-brand-3;
        box-shadow: $form-box-shadow;
        @extend .sans-regular;
        @include font-size($body-font-size);
        margin-bottom: $base-spacing / 2;
        padding: 1rem 1.5rem;
        width: 100%;

        &:hover {
            border-color: $form-border-color-hover;
        }

        &:focus {
            border-color: $form-border-color-focus;
            box-shadow: $form-box-shadow-focus;
            outline: none;
        }

        @include breakpoint($break-600) {
            padding: 1rem .5rem;
        }
    }

/*  Textarea
    ========================================================================== */

    textarea {
        resize: vertical;
    }

/*  Search
    ========================================================================== */

    input[type="search"] {
        @include appearance(none);
    }

/*  Checkbox / Radio
    ========================================================================== */

    input[type="checkbox"],
    input[type="radio"] {
        display: inline;
        margin-right: $base-spacing / 4;
    }

/*  File Select
    ========================================================================== */

    input[type="file"] {
        padding-bottom: $base-spacing / 2;
        width: 100%;
    }

/*  Select
    ========================================================================== */

    select {
        margin-bottom: $base-spacing;
        max-width: 100%;
        width: auto;
    }


/*  ========================================================================== 
    2. Form Specific Styles
    ========================================================================== */
/*  Gravity Form Defaults Override
    ========================================================================== */
    .gform_validation_container {
        display: none;
    }

    .validation_error {
        padding: 1rem 2rem;
        background-color: $error;
        color: $white;
        @extend .sans-semibold;
        text-align: center;
        border-radius: 5px;
    }

    .radio-info {
        display: none;
        width: 100%;
        margin: 10px 0px 20px;
        padding: 1rem 2rem;
        background-color: $error;
        color: $white;
        @extend .sans-semibold;
        text-align: center;
        border-radius: 5px;
        clear: both;
    }

    .gfield_error {
        .validation_message {
            color: $error;
        }

        input[type="text"] {
            border-color: $form-border-color-focus-error;
            box-shadow: $form-box-shadow-focus-error;
            outline: none;
        }
    }

/*  Base Items
    ========================================================================== */
    .gform_wrapper ul {
        padding: 0px;
        list-style: none;

        li {
            label, input {
                display: inline;
            }
        }
    }

    .gfield_label {
        @include font-size(18);
    }

    .ginput_container {
        margin-top: 10px;
    }

    .gfield_radio li {
        display: inline-block;
        margin-right: 40px;

        label {
            font-weight: normal;
        }

        input {
            margin-right: 10px;
        }
    }

    .gform_footer {
        margin-bottom: 80px;
        padding: 0px 60px;

        @include breakpoint($break-600) {
            margin-bottom: 50px;
            padding: 0px 5%;
        }
    }

    .gform_button {
        width: 220px;
        height: 60px;
        @include font-size(23);
        color: $white;
        background-color: #00a9be;
        border: none;
        @include transition();

        &:hover, &:active, &:focus {
            cursor: pointer;
            background-color: #007382;
        }
    }


/*  Custom Items
    ========================================================================== */
    .form__section {
        margin-bottom: 50px;
        padding: 40px 60px;

        @include breakpoint($break-600) {
            padding: 30px 5%;
        }
    }

    .form__header {
        margin-bottom: 0px;
        padding: 10px 60px 0px;

        @include breakpoint($break-600) {
            padding: 0px 5%;
        }
    }

    .form__creditor-details {
        margin-bottom: 0px;

        input[type="text"] {
            background-color: $color-brand-1;
            border: none;
            box-shadow: none;
            outline: none;

            &:focus {}
            &:selection {
                background: none;
            }
        }
    }

    .form__account-details {
        margin-bottom: 0px;
    }

    .form__field--tandc {
        & > label {
            display: none !important;
        }

        input {
            margin-right: 10px;
        }
    }

    .form__disclaimer {
        margin-bottom: 0px;
    }


    // Address Fields
    .ginput_container_address span label {
        display: none !important;
    }

    .ginput_container_address span {
        display: inline-block;
        width: 47%;
        margin-right: 2%;

        @include breakpoint($break-600) {
            @include stack();
            margin-right: 0px;
        }
    }

    .ginput_container_address input[type="text"] {
        width: 100%;
    }


    // Name Fields
    .ginput_container_name {
        margin-bottom: 20px;
    }

    .ginput_container_name span label {
        display: none !important;
    }

    .ginput_container_name span {
        display: inline-block;
        width: 47%;
        margin-right: 2%;

        @include breakpoint($break-600) {
            @include stack();
            margin-right: 0px;
        }
    }

    .ginput_container_name input[type="text"] {
        width: 100%;
    }

    .ginput_container_email input[type="text"] {
        text-transform: lowercase;
    }


    // Utility Classes
    .form__section--grey {
        background-color: $color-brand-1;
        @include cf();
    }

    .form__field--half {
        display: block;
        width: 47%;
        margin-right: 2%;
        float: left;

        @include breakpoint($break-600) {
            @include stack();
            margin-right: 0px;
        }
    }

    .form__field--full, .form__field--full-split {
        display: block;
        clear: both;
    }

    .form__field--margin-top {
        margin-top: 20px;
    }

    .form__field--margin-bottom {
        margin-bottom: 20px;
    }

    .gfield_visibility_hidden {
        visibility: hidden;
        opacity: 0;
        height: 0px;
    }

    .gform_hidden {
        list-style: none;
    }


    input[type=submit][disabled=disabled] {
        background-color: #eeeeee;
    }





