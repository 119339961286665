/*  ==========================================================================
    ==========================================================================

    Typography
    1. Font Definitions
    2. General Fonts

    CSS FORMATTING
    1. Positioning
    2. Display & Box Model
    3. Text
    4. Other

    ==========================================================================
    ========================================================================== */

/*  ========================================================================== 
    1. Font Definitions
    ========================================================================== */

    @include font-face(nunito_light, '../fonts/nunito-light-webfont', 300, normal);
    @include font-face(nunito_regular, '../fonts/nunito-regular-webfont', 400, normal);
    @include font-face(nunito_semibold, '../fonts/nunito-semibold-webfont', 600, normal);
    @include font-face(nunito_bold, '../fonts/nunito-bold-webfont', 700, normal);
    
    
/*  Sans Light
    ========================================================================== */

    .sans-light {
        font-family: 'nunito_light', $fallback-stack;
        font-weight: 300;
        font-style: normal;
    }
    
    
/*  Sans Regular
    ========================================================================== */

    .sans-regular {
        font-family: 'nunito_regular', $fallback-stack;
        font-weight: 400;
        font-style: normal;
    }

/*  Sans Bold
    ========================================================================== */

    .sans-semibold {
        font-family: 'nunito_semibold', $fallback-stack;
        font-weight: 600;
        font-style: normal;
    }

/*  Sans Bold
    ========================================================================== */

    .sans-bold {
        font-family: 'nunito_bold', $fallback-stack;
        font-weight: 700;
        font-style: normal;
    }

/*  ==========================================================================
    2. General Fonts 
    ========================================================================== */

/*  HTML & BODY
    ========================================================================== */

    html {
        font-size: $base-font-size;
    }

    body {
        @include font-feature-settings("kern","liga","frac", "pnum");
        -webkit-font-smoothing: antialiased;
        @extend .sans-regular;
        @include font-size($body-font-size);
        line-height: $base-line-height; 
    }

/*  Titles
    ========================================================================== */

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        //font-family: $header-font-family;
        line-height: $header-line-height;
       //margin: 0;
    }
        
    h1 {
        @include font-size($h1-font-size);
    }

    h2 {
        @include font-size($h2-font-size);
    }

    h3 {
        @include font-size($h3-font-size);
    }

    h4 {
        @include font-size($h4-font-size);
    }

    h5 {
        @include font-size($h5-font-size);
    }

    h6 {
        @include font-size($h6-font-size);
    }

    
/*  Body Copy, Anchors etc
    ========================================================================== */

    p {
        margin: 0 0 ($base-spacing / 2);
    }

    a {
        color: $base-link-color;
        text-decoration: none;

        &:hover {
            color: $hover-link-color;
        }

        &:active, &:focus {
            color: $hover-link-color;
            outline: none;
        }
    }

    strong,
    p.bold {
        @extend .sans-bold;
    }